interface Window {
    initializeRemote: (remoteKey: string, envOverride: any) => Promise<void>;
}

const getEnvironment = async (envOverride: any): Promise<{ fed_env: any, build_env: any }> => {
    if (envOverride.build_env || envOverride.fed_env) {
        return envOverride;
    }

    // Env vars for CloudOS are defined in .cloudos/service_config
    // They are then stored as /evnironment.json in S3
    const file = await fetch('/environment.json');
    return file.json();
};

const getRegion = (): string => {
	if (window.origin.includes('-eu')) {
        return 'eu';
    }

	if (window.origin.includes('-ap')) {
        return 'ap';
    }

	return '';
};

window.initializeRemote = async (remoteKey: string, envOverride: any) => {
    try {
        const { getRemote, getFederatedEnv } = await import('@innovyze/lib_get_remote');

        const environment = await getEnvironment(envOverride);
        const federatedEnv = getFederatedEnv(environment);
        const region = getRegion();

        const remote = getRemote(remoteKey as any, federatedEnv, region);

        const [remoteReference] = Object.values(remote);
        const [key, url] = remoteReference.split('@');

        return new Promise(resolve => {
            const script = document.createElement('script');

            script.src = url + '?ts=' + Date.now();
            script.async = true;
            script.onload = () => {
                // @ts-expect-error unknown key type
                resolve(window[key]);
            };
            document.head.appendChild(script);
        });
    }
    catch (err) {
        console.error(err);
    }
};

import('./index');
